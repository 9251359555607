@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background: #EFF4F7;
    font-family: "Source Sans Pro",sans-serif;
    font-weight: normal;
    line-height: 1.5;
    color: #181818;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

#root {
    height: 100%;
}